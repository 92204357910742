"use strict";

module.exports = {
  strings: {
    // Text to show on the droppable area.
    // `%{browse}` is replaced with a link that opens the system file selection dialog.
    dropHereOr: 'Drop here or %{browse}',
    // Used as the label for the link that opens the system file selection dialog.
    browse: 'browse'
  }
};