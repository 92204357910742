"use strict";

var _preact = require("preact");

const formatSeconds = require("./formatSeconds.js");

function RecordingLength(_ref) {
  let {
    recordingLengthSeconds,
    i18n
  } = _ref;
  const formattedRecordingLengthSeconds = formatSeconds(recordingLengthSeconds);
  return (0, _preact.h)("span", {
    "aria-label": i18n('recordingLength', {
      recording_length: formattedRecordingLengthSeconds
    })
  }, formattedRecordingLengthSeconds);
}

module.exports = RecordingLength;