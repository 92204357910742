"use strict";

var _preact = require("preact");

const CameraIcon = require("./CameraIcon.js");

module.exports = _ref => {
  let {
    onSnapshot,
    i18n
  } = _ref;
  return (0, _preact.h)("button", {
    className: "uppy-u-reset uppy-c-btn uppy-Webcam-button uppy-Webcam-button--picture",
    type: "button",
    title: i18n('takePicture'),
    "aria-label": i18n('takePicture'),
    onClick: onSnapshot,
    "data-uppy-super-focusable": true
  }, CameraIcon());
};