"use strict";

var _preact = require("preact");

module.exports = _ref => {
  let {
    icon,
    i18n,
    hasCamera
  } = _ref;
  return (0, _preact.h)("div", {
    className: "uppy-Webcam-permissons"
  }, (0, _preact.h)("div", {
    className: "uppy-Webcam-permissonsIcon"
  }, icon()), (0, _preact.h)("h1", {
    className: "uppy-Webcam-title"
  }, hasCamera ? i18n('allowAccessTitle') : i18n('noCameraTitle')), (0, _preact.h)("p", null, hasCamera ? i18n('allowAccessDescription') : i18n('noCameraDescription')));
};