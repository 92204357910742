"use strict";

var _preact = require("preact");

module.exports = _ref => {
  let {
    currentDeviceId,
    videoSources,
    onChangeVideoSource
  } = _ref;
  return (0, _preact.h)("div", {
    className: "uppy-Webcam-videoSource"
  }, (0, _preact.h)("select", {
    className: "uppy-u-reset uppy-Webcam-videoSource-select",
    onChange: event => {
      onChangeVideoSource(event.target.value);
    }
  }, videoSources.map(videoSource => (0, _preact.h)("option", {
    key: videoSource.deviceId,
    value: videoSource.deviceId,
    selected: videoSource.deviceId === currentDeviceId
  }, videoSource.label))));
};